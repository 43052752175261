<template>
    <div>
        
        <welcome-view></welcome-view>
        <v-parallax src="@/assets/images/background03.svg" :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" >
		<about-view></about-view>
        <services-view></services-view>
        <clients-view></clients-view>
        <contact-view></contact-view>
        <referidos-view></referidos-view>
        </v-parallax>
    </div>
</template>

<script>
import WelcomeView from "@/views/WelcomeView";
import AboutView from './AboutView.vue';
import ServicesView from './ServicesView.vue';
import ClientsView from '@/views/ClientsView';
import ReferidosView from '@/views/ReferidosView';

import ContactView from './ContactView.vue';
export default {
    components: { WelcomeView,
	AboutView,ReferidosView,
        ServicesView,
        ClientsView,
        ContactView
	},
    data() {     return {};
    },
};
</script>

<style scoped>
.v-parallax{
    /*transform: none !important;*/
    width: 100% !important;

}
</style>
