<template>
    <section id="clients">
        <v-row dense>
            <!-- <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="8" md="1">
                <div class="d-flex flex-column justify-center align-center ">
                    <v-card class="ma-10" :elevation="10">
                        <v-card-title primary-title>
                            <h1>1</h1>
                        </v-card-title>
                    </v-card>
                    <v-card class="ma-10" :elevation="10">
                        <v-card-title primary-title>
                            <h1>2</h1>
                        </v-card-title>
                    </v-card>
                    <v-card class="ma-15" :elevation="10">
                        <v-card-title primary-title>
                            <h1>3</h1>
                        </v-card-title>
                    </v-card>
                    <v-card class="ma-6" :elevation="10">
                        <v-card-title primary-title>
                            <h1>4</h1>
                        </v-card-title>
                    </v-card>                
                    <v-card class="mt-13" :elevation="10">
                        <v-card-title primary-title>
                            <h1>5</h1>
                        </v-card-title>
                    </v-card>                
                </div>
            </v-col> -->
            <v-col cols="12" md="6" >
                <div class="d-flex flex-column fill-height justify-center align-center">
                    <v-card class="my-1" :elevation="10">
                        <v-row class="align-center">
                            <v-col cols="2">
                                <v-img max-width="300" max-height="300" class="ml-5 mr-n5" src="@/assets/images/services/fooddrinks.png"></v-img>
                            </v-col>
                            <v-col cols="10">
                            <v-card-title class="primary--text" primary-title>
                                ALIMENTOS Y BEBIDAS
                            </v-card-title>
                            <v-card-text>
                                Trabajamos para el grupo mas importante en la elaboración de vidrio a nivel mundial Grupo Vitro y Grupo Owens Illinois, siempre siguiendo sus requerimientos y normas para poder cumplir con las metas pactadas entre cliente proveedor.

                            </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card class="my-1">
                        <v-row class="align-center">
                            <v-col cols="2">
                                <v-img max-width="300" max-height="300" class="ml-5 mr-n5" src="@/assets/images/services/metalmecanica.png"></v-img>
                            </v-col>
                            <v-col cols="10">
                            <v-card-title class="primary--text" primary-title>
                                    METALMECANICA
                                </v-card-title>
                                <v-card-text>
                                    Con un estricto apego a las normas de seguridad impuestas por nuestra propia organización hemos desarrollado proyectos como inspeccionando porosidad, fracturas, golpes, rebabas exceso de soldadura, siempre basados en los parámetros establecidos en el plan de control, instrucción de trabajo
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card class="my-1">
                        <v-row class="align-center">
                            <v-col cols="2">
                                <v-img max-width="300" max-height="300" class="ml-5 mr-n5" src="@/assets/images/services/automotriz.png"></v-img>
                            </v-col>
                            <v-col cols="10">
                            <v-card-title class="primary--text" primary-title>
                                    AUTOMOTRIZ
                                </v-card-title>
                                <v-card-text>
                                    Se ha tenido la oportunidad de  trabajar con diferentes empresas del sector automotriz, hemos realizado diferentes servicios tales como inspección visual , retrabajos, contenciones, residentes de calidad. 
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card class="my-1">
                        <v-row class="align-center">
                            <v-col cols="2">
                                <v-img max-width="300" max-height="300" class="ml-5 mr-n5" src="@/assets/images/services/cajas.png"></v-img>
                            </v-col>
                            <v-col cols="10">
                            <v-card-title class="primary--text" primary-title>
                                    CAJAS CHAROLAS Y SEPARADORES
                                </v-card-title>
                                <v-card-text>
                                    Uno de los tantos procesos que se tienen dentro de la industria del vidrio es el de empaque y embalaje,  trabajamos con diferentes proveedores (Bio papel, Empaques modernos, Cuatipack. Etc) enfocándonos a inspeccionar, segregar y retrabajar empaques siempre basados en el concepto de cero defectos
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card class="my-1">
                        <v-row class="align-center">
                            <v-col cols="2">
                                <v-img max-width="300" max-height="300" class="ml-5 mr-n5" src="@/assets/images/services/tarima.png"></v-img>
                            </v-col>
                            <v-col cols="10">
                            <v-card-title class="primary--text" primary-title>
                                    TARIMAS DE MADERA Y PLASTICO
                                </v-card-title>
                                <v-card-text>
                                Uno de los tantos procesos que se tienen dentro de la industria del vidrio es el de Tarimas y embalaje,  trabajamos con diferentes proveedores (Tibasa, Maderalia, Ifisa, Juan Sánchez. Etc) enfocándonos a inspeccionar, segregar y retrabajar  siempre basados en el concepto de cero defectos
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-col>
            <v-col cols="12" md="6"> 
                <v-container fluid >
                    <v-row align-content="center" justify="end" >
                        <v-col cols="6" lg="4" md="5" class="mt-1" v-for="(item,index) in clients" :key="index">
                            <css-card :data="{tittle:item.title,text:item.text}"></css-card>                    
                        </v-col>
                    </v-row>                    
                </v-container>  
                
            </v-col>
        </v-row>
    </section>    
</template>

<script>
// import CssLoader from '@/components/CssLoader'
import CssCard from '@/components/CssCard'
  
export default{
    components:{
        // CssLoader,
    CssCard
    },
    data () {
        return {
            clients:[
                {
                    title:"Grupo Owens illinois.",
                    text:"Compañía (figura en la lista del Fortune 500) especializada en la producción de envases de vidrio."
                },
                {
                    title:"Grupo Vitro.",
                    text:"Vitro es una empresa multinacional especializada en vidrio con sede en México"
                },
                {
                    title:"Plásticos técnicos.",
                    text:"Se dedica al diseño, desarrollo y manufactura de piezas plásticas y material de punto de venta para diversas industrias"
                },
                {
                    title:"Shanghai Samsung.",
                    text:"Se trata del mayor grupo empresarial surcoreano, con numerosas filiales de tecnología, finanzas, aseguradoras, etc"
                },
                {
                    title:"Imbera.",
                    text:"Empresa dedicada a la fabricación de refrigeradores y heladeras"
                },
                {
                    title:"Bohn de México.",
                    text:"Lider en México en la Fabricación de Refrigeración Comercial y Refrigeración Industrial."
                },
                {
                    title:"Centro de salud queretaro.",
                    text:"Servicios de Salud del Estado de Querétaro "
                },
                {
                    title:"Perficom.",
                    text:"Perficom integra a su operación la fabricación de las unidades insuladas"
                },
                {
                    title:"Metálicos al punto.",
                    text:"Fabricación De Material De Refrigeración Y Congelación"
                },
                {
                    title:"TMI.",
                    text:"Primer despacho en México especializado en la regulación de tecnologías emergentes y convergentes"
                },
                {
                    title:"Arelmex.",
                    text:"Empresa mexicana fundada en 1999, que se especializa en la manufactura de arneses eléctricos, automotrices e industriales"
                },
                {
                    title:"Bio pappel.",
                    text:"Compañía mexicana dedicada a la fabricación de papel y empaques de cartón"
                },
                {
                    title:"Empaques modernos de Qro.",
                    text:"Los mejores empaques de cartón y papel"
                },
                {
                    title:"Cuautipack.",
                    text:"Fabricación y comercializacón de cajas, empaques e interiores de pliego o lámina de cartón corrugado y fibra sólida"
                },
                {
                    title:"Tibasa.",
                    text:"Se Fabrican todo tipo de soluciones de empaque en madera y tableros"
                },
                {
                    title:"Maderalia.",
                    text:"Somos una empresa de tres generaciones dedicada al cultivo del bosque y a la trasformación de materias primas forestales."
                },
                {
                    title:"Ifisa.",
                    text:"Empresa forestal mexicana; dedicada a la transformación y aprovechamiento de la madera"
                },
            ]

        }
    }
}
</script>