<template>
    <section id="contact" class="mt-10">
        <v-row no-gutters>
            <v-col cols="12" sm="6">
                 <v-container align-center justify-center fluid fill-height>
                    <v-row align="center" justify="center" >                            
                        <v-col cols="12" sm="12" >
                            <v-card class="mb-3 pa-3" elevation="10" max-width="" >                      
                                <v-container class="">
                                    <v-row no-gutters>
                                        <v-col mx-2>
                                            <v-card  flat  color="transparent" >
                                                <v-card-title>
                                                    <v-col cols="12" >
                                                        <span >
                                                            <h6 class="text-center" :class="[$vuetify.breakpoint.smAndDown ? 'display-1 primary--text  text-center': 'display-2 primary--text  text-center']">
                                                                Forma de contratación
                                                            </h6>                                
                                                        </span>                                        
                                                    </v-col>                                                    
                                                </v-card-title>    
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title class="primary--text" primary-title>
                                    Por Proyecto
                                </v-card-title>
                                <v-card-text>
                                    Es un evento aislado donde el personal se presenta cuando se realiza el llamado y se retira en cuanto se concluye el sorteo. 
                                </v-card-text>
                            </v-card>    
                        </v-col>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title class="primary--text" primary-title>
                                    Residencia
                                </v-card-title>
                                <v-card-text>
                                    En esta modalidad el personal esta de planta en su empresa donde se cubren turnos específicos de acuerdo a días de la semana y cantidad de personal que el cliente nos autorice
                                </v-card-text>
                            </v-card>    
                        </v-col>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title class="primary--text" primary-title>
                                    Alcance de la operación
                                </v-card-title>
                                <v-card-text>
                                    Acceso a planta: pago SUA, alta IMSS, EPP
                                    Enfoque operaciones: Numero de partes, lotes, condiciones a trabajar, herramientas, marcas de compra, métodos de trabajo.
                                    Personal: Horarios, Turnos y cantidad de personal.
                                    Reportes: Frecuencia de envió, tipo de evidencia, Lista de distribución.
                                    Evidencia: Nuestro líder de equipo asignado a su proyecto tiene como misión capturar las imágenes mas relevantes de sus defectos.
                                </v-card-text>
                            </v-card>    
                        </v-col>
                    </v-row>
                 </v-container>
            </v-col>            
            <v-col cols="12" sm="6">
                <v-overlay :value="sent" ></v-overlay>
                <v-snackbar v-model="sent">
                    {{ snackbarText }}
                    <v-btn text color="primary" @click.native="sent = false">Close</v-btn>
                </v-snackbar>
                <v-container class="" align-center justify-center fluid  fill-height>
                    <v-row align="center"   justify="center" >                            
                        <v-col cols="12" sm="12" >
                            <v-card class="mb-3 pa-3" elevation="8" max-width="" >                      
                                <v-container class="">
                                    <v-row no-gutters>
                                        <v-col mx-2>
                                            <v-card  flat  color="transparent" >
                                                <v-card-title>
                                                    <v-col cols="12" >
                                                        <span >
                                                            <h6 class="text-center" :class="[$vuetify.breakpoint.smAndDown ? 'display-1 primary--text  text-center': 'display-2 primary--text  text-center']">
                                                                Contáctanos
                                                            </h6>                                
                                                        </span>                                        
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <span>
                                                            <h4 class="text-center">
                                                                <a href="mailto:fernando.mijares@iirmingenieria.com.mx">fernando.mijares@iirmingenieria.com.mx</a>
                                                            </h4>                                
                                                        </span>                                        
                                                    </v-col>                                        
                                                </v-card-title>    
                                            </v-card>
                                            <v-card class="mb-3 pa-3" >
                                                <v-card-title primary-title>
                                                    <h5 class="text-center" :class="[$vuetify.breakpoint.smAndDown ? 'display-1 primary--text  text-center': 'display-2 primary--text  text-center']">
                                                        Nosotros te buscamos
                                                    </h5>                                            
                                                </v-card-title>
                                                <v-form ref="mailform" @submit.prevent="sendEmail" >
                                                    <v-card-text>
                                                        <v-text-field dense v-model="nombreU" :rules="rules.nrules" outlined prepend-icon="mdi-account" placeholder="Nombre y apellidos"  label="Nombre Completo" ></v-text-field>
                                                        <v-text-field dense v-model="emailU" :rules="rules.emailrules" outlined label="Correo electrónico" placeholder="email@ejemplo.com" prepend-icon="mdi-email-edit" ></v-text-field>                                        
                                                        <v-text-field dense type="number" v-model="telefonoU" :rules="rules.telefonorules" outlined label="Telefono" placeholder="1234567890" prepend-icon="mdi-phone-in-talk" ></v-text-field>
                                                        <v-textarea dense outlined color="primary"  placeholder= "Favor de poner su mensaje o solicitud!! "
                                                                error-count=""
                                                                prepend-icon="mdi-forum"
                                                                name=""
                                                                label="Su mensaje"     
                                                                :rules="rules.mensajerules"
                                                                v-model="textU"
                                                            ></v-textarea>                                                         
                                                    </v-card-text>                                    
                                                    <v-btn  type="submit" :disabled="sent" large elevation-10 block color="primary darken-1"> 
                                                        <v-icon  left color="dark">mdi-email-send</v-icon>                                        
                                                        Enviar
                                                    </v-btn>
                                                </v-form> 
                                            </v-card>                                
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>            
            </v-col>
                
        </v-row>
    </section>
</template>

<script>
import axios from 'axios';
export default{
    
   data() {
            return {
                rules:{
                    nrules: [
                    value => !!value || 'Requerido.',
                    value => (value && value.length >= 3) || 'Mínimo 3 Caracteres',
                    ],
                    emailrules:[
                        value => !!value || 'Requerido.',
                        //value=> new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+(?:[A-Z]{2}|com|org|net|gov|gob|mil|biz|info|mobi|name|aero|jobs|museum)").test(value)|| 'Correo no válido',
                        value=> new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?").test(value)|| 'Correo no válido',
                    ],
                    mensajerules:[
                        value => !!value || 'Requerido.',
                        value => (value && value.length >= 10) || 'Mínimo 10 Caracteres',
                    ],
                    telefonorules:[
                        value => !!value || 'Requerido.',               
                        value => (value && value.length ==10) || 'Deben ser  10 Caracteres',
                        value => new RegExp("[0-9]{10}").test(value)|| 'Deben ser  10 Números',
                    ],                    
                },
                sent:false,
                nombreU: '',
                emailU: '',
                textU: '',
                telefonoU: '',
                snackbarText:'',
            }
        },
        created(){
            
        },
        methods:{
            sendEmail(e){             
            e.preventDefault();
            if(this.$refs.mailform.validate()){                
                axios.post('/contactus.php', {
                    name: this.nombreU,
                    from: this.emailU,
                    message: this.textU,
                    telefono: this.telefonoU
                })
                .then(response => {                    
                    this.nombreU = '';
                    this.emailU = '';
                    this.textU = '';
                    this.telefonoU = '';
                    console.log(JSON.stringify(response));
                    this.snackbarText="Enviado Correctamente"
                })
                .catch(error => {
                    if(error.response){                        
                        console.log(error.response.data);
                        try{
                            this.snackbarText=error.response.data.message||error.response.data.error||error.response.message || error.response.data;
                        }
                        catch(e){
                            this.snackbarText="Error en el servidor";
                        }
                    }
                    else{
                        this.snackbarText="Error al tratar de enviar el correo";
                    }
                    console.log(this.snackbarText);
                })
                .finally(() => {
                    this.sent = true;
                })

            }
        }
        }
}
</script>