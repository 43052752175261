import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/',name: 'home',component: HomeView},
  { path: '/about', name: 'about', component: () => import('../views/AboutView.vue')},
  { path: '/services', name: 'services', component: () => import('../views/ServicesView.vue')},
  { path: '/clients', name: 'clients', component: () => import('../views/ClientsView.vue')},
  { path: '/contact', name: 'contact', component: () => import('../views/ContactView.vue')},
  { path: '/referidos', name: 'referidos', component: () => import('../views/ReferidosView.vue')}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
