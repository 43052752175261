<template>
  <v-app id="app">
    <nav-bar></nav-bar>
    <v-main>
     <v-expand-transition mode="out-in">
      <router-view></router-view>
     </v-expand-transition>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar'

  export default{
	components:{NavBar},
     name: 'App',  
    data () {
      return {
                drawer: false,                   
                group:null,
        }
      },
      watch: {
            group () {
                this.drawer = false
            },
        },
  }
</script>
<style>
</style>
