import Vue from 'vue';
import Vuetify from 'vuetify/lib'


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#5daab0',
        secondary: '#d2ebe2',
        accent: '#378772',
        error: '#b71c1c',
      },
    },
  },
});
