import { render, staticRenderFns } from "./ReferidosView.vue?vue&type=template&id=377fe1be&scoped=true&"
var script = {}
import style0 from "./ReferidosView.vue?vue&type=style&index=0&id=377fe1be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "377fe1be",
  null
  
)

export default component.exports