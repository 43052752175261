<template>
    <section  id="home">      
        <v-row>
            <v-carousel  cycle :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"  >
                <v-carousel-item v-for="(item,i) in gallery" :key="i" cover :src="item.picture" reverse-transition="fade-transition" transition="fade-transition" >
                <v-container fill-height fluid>
                    <v-row align="center" justify="center">
                        <v-col  cols="12" md="6" offset-md="6"   class="fill-height"> 
                            <v-sheet color="rgb(244, 244, 244,0.8" :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"  >
                                <v-container fluid fill-height>
                                    <v-row align="center" justify="center"   no-gutters>
                                        <v-col  align="center">
                                            <v-img class="bg-transparent" v-if="item.imgTitle" :src="item.imgTitle"  height="250" width="250" >
                                                <template v-slot:placeholder>
                                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                                        <css-loader></css-loader>
                                                    </v-row>
                                                </template> 
                                            </v-img>
                                        </v-col>
                                        <v-col cols="12" sm-offset="6"  align="left"  :height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
                                            <h1 id="iirmi" class="primary--text mt-n8" :class="[$vuetify.breakpoint.smAndDown ? 'display-4 black--text  text-center': 'display-6 black--text  text-center']">  
                                                {{ item.title }}
                                                <hr v-if="item.title" style="height:10px;background:#000">  
                                            </h1>                                    
                                            <h3 class="subheading mt-10 black--text">
                                                {{ item.subtitle }}
                                                <hr v-if="item.subtitle"  >  
                                            </h3>
                                            <h4 class="subheading mt-10 justify-left align-left accent--text">
                                                <div v-if="!item.list">
                                                <h4> {{ item.text }}</h4>
                                                </div>
                                                <div v-else >
                                                    <div v-for="(titemm,index) in item.text" :key="index">
                                                        <div v-if="titemm.title">
                                                            <h1 > <v-icon left color="black" >
                                                            {{ titemm.icontitle }}
                                                            </v-icon>
                                                            {{titemm.title}}                                                
                                                            </h1>                                                
                                                        </div>
                                                        <v-icon left color="black" >mdi-check-bold</v-icon>
                                                        <h4>
                                                            {{ titemm.text }} <br>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-container>
                    <v-row no-gutters >
                        <v-col cols="6">
                            <v-img class="bg-transparent" :src="item.pictur" :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" >
                                <!-- <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <css-loader></css-loader>
                                    </v-row>
                                </template> -->
                            </v-img>
                        </v-col>
                        <v-col cols="6">
                        <div class="d-flex flex-column fill-height justify-center align-center">
                        <v-sheet color="rgb(244, 244, 244,0.8" :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"  >
                        <v-container fluid fill-height>   
                            <v-row no-gutters>
                            <div class="d-flex flex-column fill-height justify-center align-center">                            
                                
                                </div>
                                </v-row>
                                </v-container>
                            </v-sheet>
                            </div>
                        </v-col>
                    </v-row>
                </v-carousel-item>
            </v-carousel>
        </v-row>
    </section>
</template>

<script >

 import CssLoader from '@/components/CssLoader.vue'
export default{
    components:{CssLoader},
    data () {
      return {
        gallery:[
        {
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',                    
                    color:'primary',
                    title: 'IIRMI',
                    imgTitle:require('@/assets/logo_color.png'),
                    subtitle:'Ingeniería en Inspección y Reprocesos de Materiales Industriales',
                    text: 'Somos una empresa de servicio especializado y profesional de asesoría técnica, en procesos de inspección, revisión y calidad desde luego comprometida en ofrecer una solución que le permita agilizar los procesos, relacionados con la cadena de suministro donde garanticemos la entrega del producto eliminando tiempo muerto, desperdicio, paro de líneas y rechazos; sin duda un ahorro en tiempo y dinero.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ',
                    picture: require('@/assets/images/iirmi06.png')
                },
                {
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: '',
                    subtitle:'¿Quiénes Sómos?',
                    list:true,
                    text: [
                        {
                            text:"IIRMI es una empresa de servicios creada con el propósito de satisfacer las necesidades de apoyo a los negocios de las empresas que nos requieran."
                        },
                        {
                            text:"En IIRMI Nos esforzamos por ayudar al cliente a resolver los problemas mediante la información recabada durante las inspecciones de materiales, Nos gusta investigar hasta llegar a la causa raíz del problema"
                        },
                        {
                            text:"Usamos los criterios de calidad de acuerdo a las especificaciones del producto bajos las normas de calidad de la empresa."
                        },
                        {
                            text:"Contamos con personal capacitado y especializado para dar servicio a las empresas en áreas de calidad  y producción."
                        },
                        {
                            text:"Damos una solución específica de acuerdo al control de calidad, inspección, selección y retrabajos relacionados a envases de vidrio, plástico, tela, autopartes, tarimas, cartón, cajas, etiquetado, etc."
                        },
                        {
                            text:"Estamos convencidos que nuestro principal objetivo es asegurar el producto para su uso final, manteniendo los estándares de calidad y competitividad a través de una mejora continua."
                        },
                        {
                            text:"Calidad a cada proceso Nuestro equipo de trabajo cuenta con la capacidad y experiencia técnica en diferentes procesos e industrias para adaptarnos a nuestros clientes."
                        },
                        {
                            text:"Somos los aliados de nuestros clientes en la calidad de sus procesos convirtiéndonos en parte de su equipo compartiendo los objetivos y metas de la empresa."
                        },
                    ],
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ',
                    picture: require('@/assets/images/iirmi04.png')
                },
                {
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: '',
                    subtitle:'',
                    list:true,
                    text: [
                        {
                            icontitle:"mdi-bullseye-arrow",
                            title:"Misión",
                            text:"Ofrecemos el servicio en tiempo y forma para satisfacer las necesidades de sus clientes con los más altos estándares de calidad."

                        },
                        {
                            icontitle:"mdi-eye-check",
                            title:"Visión",
                            text:"Ser la empresa de servicios más destacada a nivel nacional para la industria, bancos, hospitales. etc. que asegure la calidad de sus productos y servicios a través de un equipo de trabajo eficiente con principios de honestidad y compromiso, disciplinado, seguro y responsable."

                        },
                        {
                            icontitle:"mdi-diamond-stone",
                            title:"Valores",
                            text:"Generar confianza y credibilidad en clientes, proveedores y empleados, crear una cultura organizacional, lograr una baja de rotación de personal, trabajo en equipo, calidad, puntualidad y honestidad."

                        },
                    ],
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ',
                    picture: require('@/assets/images/iirmi01.png')
                },
            ]
        }
    }
}

</script>
<style scoped >
    #iirmi{
        font-size: 6rem;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
</style>
