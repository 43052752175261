<template>
    <div class="card">
      <div class="align">        
      </div>
      <h1 class="mt-n1 secondary--text">{{ data.tittle }}</h1>
      <p class="mt-15 black--text">
          {{ data.text }}
      </p>
</div>
</template>

<script>
export default{
    props:['data'],
    data () {
        return {
        }
    }
}
</script>

<style  scoped>
.card {
  width: 210px;
  height: 90px;
  padding: 0.5rem;
  background: rgba(198, 198, 198, 0.34);
  border-radius: 8px;
  backdrop-filter: blur(5px);
  position: relative;
  /* 
  position: absolute; 
  position: static; 
  */
  border-bottom: 3px solid rgba(255, 255, 255, 0.440);
  border-left: 2px  rgba(255, 255, 255, 0.545) outset;
  box-shadow: -40px 50px 30px rgba(0, 0, 0, 0.280);
  transform: skewX(10deg);
  transition: .4s;
  overflow: hidden;
  color: white;  
}

.card:hover {
  height: 284px;
  transform: skew(0deg);
  z-index:10000;
}

.card:active {
  height: 284px;
  transform: skew(0deg);
  z-index:10000;
}

.align {
  /* padding: 1rem; */
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-self: flex-start;
}

.card h1 {
  text-align: center;
  margin: 1.3rem;
  color: rgb(218, 244, 237);
  text-shadow: -10px 5px 10px rgba(0, 0, 0, 0.573);
}

</style>