<template>
  <section id="services">
    <v-row>
		<v-col>
        <v-container fluid>
            <v-row align="center">
                <v-col cols="12" md="4">
                    <!-- <div class="d-flex flex-column fill-height justify-center align-center "> -->
                    <!-- <div row > -->
                        <!-- <h1  class="text-center black--text" >
                            Nuestros Servicios
                        </h1> -->
                        <v-img class="bg-transparent mt-15" :elevation="15" src="@/assets/images/nuestros.png"></v-img>
                    <!-- </div> -->
                </v-col>
                <v-col cols="12" md="8">
                <v-hover v-for="(item,i) in items" :key="i"  v-slot="{ hover }">  
                    <!-- <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }"> -->
                    <v-card :elevation="hover ? 12 : 2" class="my-2">
                    <v-card-title class="primary--text" primary-title>
                       {{ item.title }}
                    </v-card-title>
                    <v-card-text>
                        {{ item.text }}
                        <div v-if="item.list">
                            <span v-for="(it,i) in item.list" :key="i" class="text-center">
                            <h5> {{ it.sub }}</h5>
                            </span>
                        </div>
                    </v-card-text>
                    </v-card>                
                </v-hover>
                </v-col>                
            </v-row>
        </v-container>
		</v-col>
    </v-row>
  </section>
</template>

<script>
 export default {
   data() {
        return {
            items:[
                {
                    icon:'mdi-recycle-variant',
                    title:"RETRABAJO DE MATERIAL",
                    text:"Modificamos las características físicas del producto siguiendo los lineamientos y/o especificaciones otorgadas por nuestros clientes y recuperación del material rechazado durante la selección."
                },
                {
                    title:"LIMPIEZA DE MATERIAL",
                    text:" Quitar suciedad, Oxido, partículas y/o materiales ajenos al producto que se requiera limpiar."
                },
                {
                    title:"EMPAQUE DE MATERIAL",
                    text:"Seguir la especificación de colocación del material dentro del empaque, envase, caja, etc."
                },
                {
                    title:"INSPECCION DE MATERIAL",
                    text:"Al tacto y/o con instrumentos de medición según se requiera verificar el material según los estándares de calidad previamente establecidos. Los resultados son registrados en un reporte por escrito y con fotografía como evidencia."
                },
                {
                    title:"SELECCIÓN DE MATERIAL",
                    text:"Separamos el material que cumpla con los estándares establecidos, se reporta lo rechazado resguardando el material."
                },                
                {
                    title:"SORTEO DE MATERIALES Y COMPONENTES",
                    text:"Se realiza la inspección de posible defecto en materiales y componentes mediante la revisión pieza por pieza por parte de nuestro equipo capacitado. A partir de esto se realiza la clasificación y reporte para el uso retrabajo y/o desecho de los componentes y materiales inspeccionados."
                },                
                {
                    title:"CONSULTORIA",
                    text:"Nuestra consultoría va desde el asesoramiento hasta la implementación, Actualización, Mantenimiento, Preparación para auditorias y mejora en sus sistemas de control de calidad. Compartimos nuestros conocimientos y experiencia en diferentes áreas de control de calidad .",
                    list:[
                        {
                            sub:"METROLOGIA"                            
                        },
                        {
                            sub:"ISO 9001"                            
                        },
                        {
                            sub:"INOCUIDAD"                            
                        },
                    ]
                },                
            ]
        };
    },
 }
</script>

<style scoped>

</style>
